import styled from "@emotion/styled";
import { css } from "@emotion/react";

import SwipeIcon from "../../assets/svg/swipe.inline.svg";

export const Swipe = styled(SwipeIcon)`
  width: 42px;
  height: 42px;
  margin: 20px auto 0;
  display: block;
  fill: #4a4a4a;
`;

export const ResourcesWrap = styled.div`
  position: absolute;
  right: 0;
  top: 27%;
  bottom: 9%;
  width: 62vw;
  display: flex;
  align-items: center;
  padding: 0 calc(90px + (100vw - 1366px) * 0.11);
  @media (max-width: 1200px) {
    padding: 0 12%;
  }
  @media (max-width: 768px) {
    position: relative;
    top: initial;
    bottom: initial;
    right: initial;
    font-kerning: initial;
    width: initial;
    padding: 35px;
    .swipe-icon {
      display: none;
    }
    .gatsby-image-wrapper {
      margin-bottom: 10px;
      img {
        max-height: 350px;
      }
    }
    .carousel-child {
      width: 200px;
    }
    .resource-name {
      font-size: 26px;
    }
  }
  @media (max-width: 650px) {
    padding: 35px 0;
    width: 290px;
    margin: 0 auto;
  }
`;

export const SliderWrap = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const carouselContent = css`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  position: relative;
  height: 100%;
`;

export const CarouselChild = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  user-select: none;
`;

export const carouselIndicators = css`
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  margin: 10px auto 0;
`;

export const carouselIndicatorWrap = css`
  height: 22px;
  width: 22px;
  border-radius: 50%;
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const CarouselIndicator = styled.div`
  position: relative;
  background-color: #808080;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 50%;
  padding: 0;
  transition: transform 0.2s, background-color 0.2s;
  .active.carousel-indicator & {
    background-color: #fff;
    transform: scale(1.5);
  }
`;

export const slideContentWrap = css`
  flex: 10;
`;

export const resourcePreview = css`
  width: 100%;
  border-top-left-radius: 17.1%;
  border-bottom-right-radius: 17.1%;
  margin-bottom: 35px;
`;

export const resourceLeftLine = css`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text {
    fill: #bebebe;
    font-size: 11px;
    font-weight: 300;
    font-family: var(--sec-font);
    letter-spacing: 0.04em;
  }
`;

export const resourceTitle = css`
  font-family: var(--sec-font);
  font-weight: bold;
  font-size: 30px;
  letter-spacing: 1.2px;
  color: #4d4d4d;
  text-transform: uppercase;
  margin: 10px 0;
  padding-right: 10px;
`;

export const resourceDescription = css`
  font-size: 16px;
  line-height: 21.2px;
  color: #3a383c;
  padding-right: 10px;
  margin: 15px 0 0;
`;
