import styled from "@emotion/styled";
import bgMap from "../../assets/images/map-bg-op.jpg";

export const contListTrans = () => {
  let styles = {};
  for (let $i = 0; $i < 6; $i++) {
    styles["&:nth-of-type(" + $i + ")"] = {
      transform: `translateY(-${($i - 1) * (35 + 30)}px)`,
    };
  }
  return styles;
};

export const MapBackground = styled.div`
  max-width: 75vw;
  flex: 1;
  position: relative;
  @media (max-width: 1366px) {
    max-width: calc(100% - 270px);
  }
`;

export const MapWrapper = styled.div`
  background-image: url(${bgMap});
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 25px;
  display: flex;
  @media (max-width: 768px) {
    position: relative;
    /* bottom: initial; */
    /* height: calc(218px + (100vw - 550px) * 0.383); */
    height: 80vh;
    height: 550px;
    padding-top: 80px;
    max-height: calc(100% - 180px);
    .map-background {
      max-width: 100%;
      /* width: 100%; */
    }
  }
  @media (max-width: 550px) {
    height: 40%;
    padding: 0 0 40px;
    svg {
      position: relative !important;
    }
    .map-background {
      max-width: 100%;
      padding-top: 60px;
      /* width: 100%; */
    }
  }
`;

export const ListContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 20vw;
  padding: 6.5vw 2.7vw;
  padding-top: 8vw;
  .last-row & {
    padding-top: 6vw;
  }
  @media (max-width: 1365px) {
    width: 29vw;
    padding: 25px 15px;
  }
  @media (max-width: 768px) {
    top: initial;
    bottom: 0;
    height: 70%;
    width: 100%;
    height: auto;
    position: relative;
    margin-top: -36px;
    padding: 20px;
    /* padding-top: 15px !important; */
    padding-top: 60px !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1120 250'%3E%3Cpath d='M0 250V60h260c41-3 28-55 68-60h792v250z' fill='%233a383c'/%3E%3C/svg%3E"),
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 9500'%3E%3Cpath d='M0 0v9500h1000V0z' fill='%233a383c'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 0px 0px, 0px 50px;
    background-size: 100%, 100%;
    display: flex;
    .all-continents {
      display: flex;
      flex: 1;
    }
    .continent-li {
      flex: 1;
      margin: 0 5px 25px;
    }
    .country-list {
      position: relative;
      bottom: initial;
      left: initial;
      visibility: visible;
      max-height: initial;
    }
    .all-continents-mob {
      display: grid;
      grid-template-areas: "a b" "a c" "a d" "a .";
      grid-template-areas: "a b" "c d";
      grid-template-columns: 1fr 1fr;
      width: 100%;
    }
    > div {
      width: 100%;
    }
    .last-row & {
      padding-bottom: 70px;
    }
  }
  @media (max-width: 480px) {
    .all-continents-mob {
      grid-template-areas: "a" "b" "c" "d";
      grid-template-columns: 1fr;
    }
  }
`;

export const Continent = styled.li`
  display: flex;
  flex-flow: column;
  color: #fff;
  margin-bottom: calc(8px + (100vw - 1366px) * 0.04);
  line-height: 35px;
  position: relative;
  @media (max-width: 1366px) {
    margin-bottom: 8px;
  }
`;

export const ContinentName = styled.span`
  text-transform: uppercase;
  cursor: pointer;
  font-size: calc(29px + (100vw - 1366px) * 0.0126);
  line-height: 1;
  margin-bottom: 6px;
  font-weight: bold;
  font-family: var(--sec-font);
  transition: color 0.3s;
  z-index: 4;
  li.active & {
    color: #ba0027;
  }
  @media (hover: hover) {
    &:hover {
      color: #ba0027;
    }
  }
  @media (max-width: 1366px) {
    font-size: 29px;
  }
`;

export const CountryList = styled.ul`
  position: absolute;
  bottom: 100%;
  left: 10px;
  right: 0;
  opacity: 1;
  visibility: hidden;
  max-height: 47vh;
  display: flex;
  flex-flow: column;
  .ScrollbarsCustom-Content {
    flex-flow: column;
    align-items: flex-start;
  }
  @media (max-width: 1365px) {
    max-height: 35vw;
  }
  @media (max-width: 768px) {
    height: 0;
    overflow: hidden;
  }
`;

export const CountryName = styled.li`
  font-size: 19px;
  line-height: 1;
  margin: 5px 0;
`;

export const GoBack = styled.div`
  margin-left: -10px;
  opacity: 1;
  visibility: hidden;
  svg {
    fill: #fff;
    width: 2.6vw;
    width: calc(180px + (100vw - 1366px) * 0.055);
    height: 20px;
    cursor: pointer;
    text {
      font-family: var(--sec-font);
      transition: fill 0.3s;
      fill: #ccc;
      font-size: 14px;
    }
  }
  &:hover svg text {
    fill: #ba0027;
  }
`;
