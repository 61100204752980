import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const RecentWrapper = styled.div`
  background-color: #ddd;
  display: flex;
  height: 100%;
  align-items: center;
  transition: filter 0.5s, opacity 0.5s;
  pointer-events: all;
  .gatsby-image-wrapper {
    transition: opacity 500ms;
  }
  .state-expanded & {
    filter: blur(10px);
    opacity: 0.15;
    pointer-events: none;
    .carousel-content {
      pointer-events: none !important;
    }
  }
  @media (max-width: 1365px) {
    padding: 75px 0 25px;
  }
  @media (max-width: 768px) {
    padding: 15px 0 45px;
    .carousel-indicators {
      bottom: 15px;
    }
    .last-row & {
      padding-bottom: 95px;
      .carousel-indicators {
        bottom: 80px;
      }
    }
  }
`;

export const SliderWrap = styled.div`
  // position: absolute;
  overflow: hidden;
  width: 100%;
  width: 83.73vw;
  height: 71%;
  margin: 40px auto 0;
  @media (max-width: 768px) {
    height: auto;
  }
`;

export const carouselContent = css`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  position: relative;
  height: 100%;
`;

export const CarouselChild = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  user-select: none;
  img {
    user-drag: none;
  }
`;

export const carouselArrow = (props) => css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
  padding: 5px 15px;
  transition: opacity 0.2s;
  left: ${props.left || "initial"};
  right: ${props.right || "initial"};
  opacity: ${props.opacity};
  > svg {
    height: 2.68vw;
    color: #bebebe;
    fill: currentColor;
  }
  @media (max-width: 1200px) {
    width: 30px;
    padding: 6px;
    > svg {
      height: 100%;
    }
    left: ${props.left && "5px"};
    right: ${props.right && "5px"};
  }
  @media (max-width: 500px) {
    width: 35px;
    height: 48px;
    left: ${props.left && "0px"};
    right: ${props.right && "0px"};
  }
`;

export const leftBarWrap = css`
  position: relative;
  flex: 56;
  padding: 0 10px;
  transition: transform 0.4s ease-in-out 0.2s;
  @media (max-width: 500px) {
    padding: 0 0 0 7px;
    flex: 40;
    .leftbar-pagerow {
      margin-right: 15px;
    }
    .content-title-wrap {
      right: 2px;
      > * {
        font-size: 29px;
      }
    }
  }
`;

export const slideContent = css`
  flex: 266;
`;

export const slideImageWrap = css`
  position: relative;
  max-width: 340px;
  img {
    border-bottom-right-radius: 17.1%;
    border-top-left-radius: 17.1%;
  }
`;

export const Excerpt = styled.p`
  line-height: 1.3;
`;

export const newsThumb = css`
  height: 0 !important;
  width: calc(233px + (100vw - 1366px) * 0.193) !important;
  padding-top: calc(233px + (100vw - 1366px) * 0.193);
  @media (max-width: 1200px) {
    width: 100% !important;
    padding-top: 100%;
  }
`;

export const recentNewsTitle = css`
  font-family: var(--main-font);
  font-weight: 700;
`;

export const carouselIndicators = css`
  position: absolute;
  bottom: 6%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  margin: 0 auto;
`;

export const carouselIndicatorWrap = css`
  height: 22px;
  width: 22px;
  border-radius: 50%;
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 6px;
    margin: 0 3px;
  }
`;

export const CarouselIndicator = styled.div`
  position: relative;
  background-color: #808080;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 50%;
  padding: 0;
  transition: transform 0.2s, background-color 0.2s;
  border: 1px solid transparent;
  .active.carousel-indicator & {
    background-color: #fff;
    transform: scale(1.5);
    @media (max-width: 768px) {
      border-color: #ba0027;
    }
  }
`;
