import styled from "@emotion/styled";
import { css } from "@emotion/react";

import LabelIcon from "../../assets/svg/label-box.inline.svg";
import SubmitIcon from "../../assets/svg/submit.inline.svg";
import DropDownIcon from "../../assets/svg/dropdown.inline.svg";

export const buttonSubmit = css`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  @media (max-width: 550px) {
    margin: 10px 0;
    margin-left: auto;
    background-color: #3a383c;
    padding: 5px 15px;
    height: 36px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 5px;
    svg {
      display: none;
    }
  }
`;

export const SubmitButton = styled(SubmitIcon)`
  width: 150px;
  /* font-family: "Roboto"; */
  font-weight: bold;
  font-size: 14px;
`;

export const DropDown = styled(DropDownIcon)`
  fill: #858585;
  width: 20px;
  height: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  pointer-events: none;
  @media (max-width: 550px) {
    top: 45px;
  }
`;

export const LabelBox = styled(LabelIcon)`
  width: 150px;
  @media (max-width: 550px) {
    display: none;
  }
`;

export const FormWrap = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40vw;
  display: flex;
  align-items: center;
  z-index: 1 !important;
  @media (max-width: 768px) {
    position: relative;
    top: initial;
    left: initial;
    right: initial;
    height: initial;
    width: auto;
    padding: 0 15px;
    .form-content {
      width: auto;
    }
  }
  @media (max-width: 350px) {
    padding: 0 5px;
  }
`;

export const FormContent = styled.div`
  width: 35vw;
  margin: 0 auto;
  position: relative;
  @media (max-width: 1365px) and (min-width: 769px) {
    width: 100%;
    padding: 0 15px;
  }
`;

export const cfTitle = css`
  font-size: 30px;
  color: #c1c1c1;
  text-transform: uppercase;
`;

export const FormLabel = styled.label`
  @media (max-width: 550px) {
    position: relative;
    margin-bottom: 5px;
  }
`;

export const FormLabelName = styled.span`
  position: absolute;
  left: 10px;
  top: 12px;
  font-weight: bold;
  font-size: 16px;
  @media (max-width: 550px) {
    position: relative;
    top: initial;
    left: initial;
  }
`;

export const formElement = css`
  outline: none;
  width: 100%;
  border: none;
  border-radius: 10px;
  background: none;
  @media (max-width: 550px) {
    background-color: #e6e6e6;
  }
`;

export const formInput = css`
  height: 43px;
  line-height: 43px;
  padding-left: 5px;
`;

export const formSelect = css`
  -webkit-appearance: none;
  &:required:invalid {
    color: gray;
  }
  option {
    color: black;
    &[value=""][disabled] {
      display: none;
    }
  }
`;

export const formTextarea = css`
  resize: none;
  height: calc(130px + (100vw - 1366px) * 0.0957);
  padding: 65px 5px 5px;
  @media (max-width: 1366px) {
    height: 130px;
  }
  @media (max-width: 550px) {
    padding: 5px;
  }
`;

export const checkboxLabel = css`
  flex: 1;
  line-height: 1.5;
  padding-left: 4px;
  a {
    text-decoration: underline;
    color: #ba0027;
  }
`;

export const formField = css`
  margin-bottom: 3.7vh;
  position: relative;
  display: flex;
  height: 45px;
  @media (max-width: 550px) {
    flex-flow: column;
    height: initial;
    border: none;
    margin-bottom: 25px;
    &.mob-flexrow {
      flex-flow: row wrap;
    }
  }
`;

export const formFieldBorder = css`
  border: 1px solid #ececec;
  border-radius: 10px;
  border-bottom-right-radius: 0;
  background-color: #fff;
`;

export const ErrorField = styled.span`
  position: absolute;
  color: #ba0027;
  top: calc(100% + 4px);
  left: 0;
  padding-left: 160px;
  font-size: 12px;
  @media (max-width: 550px) {
    padding-left: 0;
  }
`;

export const messageSent = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;
  &.sent-successfuly {
    opacity: 1;
    visibility: visible;
  }
`;

export const messageSentContainer = css`
  font-size: 28px;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
`;

export const lastField = css`
  margin: 0;
  @media (max-width: 1200px), (max-height: 640px) {
    display: flex;
    button {
      margin-left: auto;
    }
  }
`;
