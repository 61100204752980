import React, { useState, useCallback } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useCarousel } from "../../hooks/useCarousel";
import classnames from "classnames";

import { GatsbyImage } from "gatsby-plugin-image";

import ChevronUp from "../../assets/svg/chevron-up.inline.svg";
import ChevronDown from "../../assets/svg/chevron-down.inline.svg";

import {
  TimelineWrap,
  YearsWrap,
  yearsContainer,
  CarouselChild,
  carouselContent,
  ActiveYear,
  SubYear,
  ActiveDesc,
  currYear,
  nextYear,
  topPart,
  botPart,
  YearControls,
  yearButton,
  yeardash,
} from "./Components";

const Timeline = () => {
  const data = useStaticQuery(graphql`
    query TimelineQAsuery {
      strapiPagerows(id: { eq: "Pagerows_4" }) {
        dynamic_components
      }
      allFile(filter: {parent: {id: {eq: "Pagerows_4"}}}) {
        nodes {
          id
          name
          localURL
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 90)
          }
        }
      }
    }
  `);
  // const { dynamic_components: timeline } = data.strapiPagerows;
  const timeline = data.strapiPagerows.dynamic_components.map(el => {
    el.background.localFile = data.allFile.nodes.find(node => node.id === el.background.localFile___NODE);
    return el;
  });

  const [year, setYear] = useState(0);
  const [animateTo, setAnimateTo] = useState(0);
  const [animating, setAnimating] = useState(false);

  const showYear = useCallback(
    (year, cssEl) => {
      if (timeline[year]) {
        return (
          <div css={cssEl} className={classnames({ animate: animating })}>
            <div css={topPart} className="top-part">
              <SubYear className="subyear">
                {timeline[year].year.substring(0, 2)}
              </SubYear>
              <SubYear className="subyear">
                {timeline[year].year.substring(2, 4)}
              </SubYear>
            </div>
            <div css={botPart} className="bot-part">
              <SubYear className="subyear">
                {timeline[year].year.substring(0, 2)}
              </SubYear>
              <SubYear className="subyear">
                {timeline[year].year.substring(2, 4)}
              </SubYear>
            </div>
          </div>
        );
      } else {
        return "";
      }
    },
    [animating, timeline]
  );

  const slideOptions = {
    slidesToShow: 1,
    length: timeline.length,
    margin: 0,
    infinite: false,
    fade: true,
  };

  const { setActive, handlers, style, slideStyle } = useCarousel(
    slideOptions,
    false
  );

  const scrollPrev = () => {
    if (year > 0 && !animating) {
      setAnimateTo(year - 1);
      setAnimating("next");
      setActive(year - 1);
      setTimeout(() => {
        setYear(year - 1);
        setAnimating(false);
      }, 300);
    }
  };
  const scrollNext = () => {
    if (year < timeline.length - 1 && !animating) {
      setAnimateTo(year + 1);
      setAnimating("next");
      setActive(year + 1);
      setTimeout(() => {
        setYear(year + 1);
        setAnimating(false);
      }, 300);
    }
  };

  return <>
    <TimelineWrap>
      <div
        className="carousel-content"
        css={carouselContent}
        ref={handlers.ref}
        style={style}
      >
        {timeline.map((el, i) => {
          return (
            <CarouselChild
              key={i}
              style={slideStyle}
              className={classnames({
                fadeIn: i === year,
                animating: animateTo === i,
              })}
            >
              <GatsbyImage
                image={el.background.localFile.childImageSharp.gatsbyImageData}
                style={{ width: "100%" }}
                alt={el.background.alternativeText || ''} />
            </CarouselChild>
          );
        })}
      </div>
    </TimelineWrap>
    <YearsWrap className="hide-on-expanded">
      <div css={yearsContainer}>
        <ActiveYear>
          {showYear(year, currYear)}
          {showYear(animateTo, nextYear)}
        </ActiveYear>
        <YearControls>
          <button
            tabIndex="-1"
            css={[yearButton, { top: "10px" }]}
            onClick={scrollNext}
          >
            <ChevronUp />
          </button>
          {Array.from(Array(5)).map((el, i) => (
            <div key={i} css={yeardash} />
          ))}
          <button
            tabIndex="-1"
            css={[yearButton, { top: "10px" }]}
            onClick={scrollPrev}
          >
            <ChevronDown />
          </button>
        </YearControls>
      </div>
      <ActiveDesc>{timeline[animateTo].description}</ActiveDesc>
    </YearsWrap>
  </>;
};

export default Timeline;