import React, { useEffect, useRef, useState, useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useWindowSize } from "../../hooks/useWindowSize";
import Media from "react-media";

import { EventsWrap, eventsTitle, SingleEvent, eventTitle } from "./Components";

// import ScrollBar from "react-scrollbars-custom";
import ScrollBar from "../../containers/Scrollbar";

const Events = () => {
  const data = useStaticQuery(graphql`
    query EventsQueryQA {
      allStrapiEvent {
        nodes {
          id
          location
          title
          date
          link
          item_order
        }
      }
    }
  `);

  const { nodes: events } = data.allStrapiEvent;
  const screenRes = useWindowSize().width;
  const areaHeight = useRef(420);
  const eventsRef = useRef();
  const [triggerReflow, setReflow] = useState(0);

  // if (events.length) {
  //   events.sort((a, b) => {
  //     if (
  //       (a.item_order === null && b.item_order !== null) ||
  //       (a.item_order > b.item_order && b.item_order !== null) ||
  //       (a.item_order === null && b.item_order === null)
  //     ) {
  //       return 1;
  //     } else if (
  //       a.item_order === null ||
  //       b.item_order === null ||
  //       a.item_order < b.item_order
  //     ) {
  //       return -1;
  //     } else {
  //       return 0;
  //     }
  //   });
  // }

  const sorted_events = useMemo(() => {
    if (!events.length) {
      return [];
    }

    return [...events]
      .map((el, i) => {
        el.initialSort = i;
        return el;
      })
      .sort((a, b) => {
        if (a.item_order === null && b.item_order === null) {
          return a.initialSort - b.initialSort;
        } else if (a.item_order === null || b.item_order === null) {
          return b.item_order - a.item_order;
        } else {
          return a.item_order - b.item_order;
        }
      });
  }, [events]);

  useEffect(() => {
    if (eventsRef.current) {
      const cs = window.getComputedStyle(eventsRef.current);
      // const winHeight = window.innerHeight;
      // const availableWinHeight = winHeight * 0.15 + eventsRef.current.firstChild.offsetHeight + winHeight * 0.15;
      areaHeight.current = eventsRef.current.offsetHeight - parseFloat(cs.paddingTop) - parseFloat(cs.paddingBottom) - eventsRef.current.firstChild.offsetHeight;
      // areaHeight.current = 

      setReflow((triggerReflow) => triggerReflow + 1);
    }
  }, [eventsRef, screenRes]);

  return (
    <Media
      queries={{
        mobile: "(max-width: 768px)",
        tablet:
          "(min-width: 768px) and (orientation: portrait), (min-width: 991px)",
        desktop: "(min-width: 1366px)",
      }}
      defaultMatches={{ mobile: true }}
    >
      {(matches) =>
        matches.mobile ? (
          <EventsWrap className="hide-on-expanded" ref={eventsRef}>
            <div>
              <h2 css={eventsTitle}>Event Calendar</h2>
              <p css={{ color: "#fff", marginBottom: "25px" }}>
                Come and say hello at our exhibitions and trade shows.
              </p>
            </div>
            <div
              css={{
                // height: areaHeight.current + "px",
                display: "flex",
                flexFlow: "column",
              }}
            >
              {sorted_events.length ? (
                sorted_events.map((el, i) => (
                  <SingleEvent key={i}>
                    <a href={el.link} target="_blank" rel="noopener noreferrer">
                      <p>{el.date}</p>
                      <h3 css={eventTitle}>{el.title}</h3>
                      <p>{el.location}</p>
                    </a>
                  </SingleEvent>
                ))
              ) : (
                <p css={{ color: "#fff" }}>
                  Currently, there are no scheduled events. Please, check later.
                </p>
              )}
            </div>
          </EventsWrap>
        ) : (
          <EventsWrap className="hide-on-expanded" ref={eventsRef}>
            <div>
              <h2 css={eventsTitle}>Event Calendar</h2>
              <p css={{ color: "#fff", marginBottom: "25px" }}>
                Come and say hello at our exhibitions and trade shows.
              </p>
            </div>
            <div className="events-cont" css={{ height: areaHeight.current + "px", display: "flex" }}>
              <ScrollBar
                triggerReflow={triggerReflow}
                customStyles={{ width: "100%" }}
              >
                {sorted_events.length ? (
                  sorted_events.map((el, i) => (
                    <SingleEvent key={i}>
                      <a
                        href={el.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p>{el.date}</p>
                        <h3 css={eventTitle}>{el.title}</h3>
                        <p>{el.location}</p>
                      </a>
                    </SingleEvent>
                  ))
                ) : (
                  <p css={{ color: "#fff" }}>
                    Currently, there are no scheduled events. Please, check
                    later.
                  </p>
                )}
              </ScrollBar>
            </div>
          </EventsWrap>
        )
      }
    </Media>
  );
};

export default Events;