import React, { useEffect, useRef, useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";
import classnames from "classnames";
import Media from "react-media";

import { useCarousel } from "../../hooks/useCarousel";
import { useWindowSize } from "../../hooks/useWindowSize";

import CarouselChild from "./CarouselChild";
import {
  RecentWrapper,
  SliderWrap,
  carouselArrow,
  carouselContent,
  carouselIndicators,
  carouselIndicatorWrap,
  CarouselIndicator,
} from "./Components";

import ChevronRight from "../../assets/svg/chevron-right.inline.svg";
import ChevronLeft from "../../assets/svg/chevron-left.inline.svg";

const RecentNews = () => {
  const data = useStaticQuery(graphql`
    query RecentArticlesQA {
      allStrapiArticle(sort: { fields: created_at, order: DESC }, limit: 100) {
        nodes {
          title
          created_at
          content
          excerpt
          slug
          item_order
          thumbnail {
            alternativeText
            localFile {
              childImageSharp {
                thumbImg: gatsbyImageData(
                  width: 381,
                  quality: 100,
                  transformOptions: { cropFocus: CENTER },
                  layout: CONSTRAINED,
                  aspectRatio: 1
                )
              }
            }
          }
        }
      }
    }
  `);

  const screenRes = useWindowSize().width;

  const { nodes } = data.allStrapiArticle;
  console.log('Fetched nodes:', nodes);

  const sorted_news = useMemo(() => {
    if (!nodes.length) {
      return [];
    }

    return [...nodes]
      .map((el, i) => {
        el.initialSort = i;
        return el;
      })
      .sort((a, b) => {
        if (a.item_order === null && b.item_order === null) {
          return a.initialSort - b.initialSort;
        } else if (a.item_order === null || b.item_order === null) {
          return b.item_order - a.item_order;
        } else {
          return a.item_order - b.item_order;
        }
      });
  }, [nodes]);

  const currentNodes = useRef(sorted_news);

  useEffect(() => {
    if (screenRes < 750) {
      currentNodes.current = nodes.slice(0, 5);
    } else if (screenRes < 1201) {
      currentNodes.current = nodes.slice(0, 10);
    } else {
      currentNodes.current = nodes;
    }
    console.log('Current nodes after screen resolution change:', currentNodes.current);
  }, [screenRes, nodes]);

  const excerpts = useRef(currentNodes.current.map((el) => el.excerpt));

  const slideOptions = {
    slidesToShow: 3,
    sens: 0.6,
    interval: 3000,
    length: currentNodes.current.length,
    margin: 70,
    infinite: false,
    responsive: [
      {
        breakpoint: 1500,
        options: {
          slidesToShow: 3,
          margin: 35,
        },
      },
      {
        breakpoint: 1200,
        options: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 750,
        options: {
          slidesToShow: 1,
          sens: 6,
        },
      },
    ],
  };

  const {
    active,
    currShownSlides,
    maxSlide,
    setActive,
    handlers,
    style,
    slideStyle,
  } = useCarousel(slideOptions, false);

  const slideToLeft = () => {
    active > 0 && setActive(active - 1);
  };

  const slideToRight = () => {
    active < slideOptions.length - 1 && setActive(active + 1);
  };

  return (
    <RecentWrapper>
      {currentNodes.current.length ? (
        <>
          <button
            css={carouselArrow({
              left: "4.25vw",
              opacity: active === 0 ? "0" : "1",
            })}
            onClick={slideToLeft}
          >
            <ChevronLeft />
          </button>
          <button
            css={carouselArrow({
              right: "4.25vw",
              opacity: active === maxSlide ? "0" : "1",
            })}
            onClick={slideToRight}
          >
            <ChevronRight />
          </button>
          <SliderWrap>
            <div
              className="carousel-content"
              css={carouselContent}
              {...handlers}
              style={style}
            >
         {currentNodes.current.map((el, i) => {
  if (!el.thumbnail || !el.thumbnail.localFile || !el.thumbnail.localFile.childImageSharp) {
    console.log(`Missing image for article: ${el.title}`);
  }
  
  return (
    <CarouselChild
      key={i}
      slideStyle={slideStyle}
      slug={el.slug}
      title={el.title}
      alt={el.thumbnail ? el.thumbnail.alternativeText || '' : ''}
      thumbImg={el.thumbnail && el.thumbnail.localFile && el.thumbnail.localFile.childImageSharp ? el.thumbnail.localFile.childImageSharp.thumbImg : null}
      excerpt={excerpts.current[i]}
    />
  );
})}

            </div>
          </SliderWrap>
          <Media
            query="(max-width: 768px)"
            render={() => (
              <ol className="carousel-indicators" css={carouselIndicators}>
                {[
                  ...Array(currentNodes.current.length - currShownSlides + 1),
                ].map((_, index) => (
                  <li
                    key={index}
                    css={carouselIndicatorWrap}
                    className={classnames("carousel-indicator", {
                      active: active === index,
                    })}
                    onClick={() => setActive(index)}
                    role="presentation"
                  >
                    <CarouselIndicator />
                  </li>
                ))}
              </ol>
            )}
          />
        </>
      ) : (
        ""
      )}
    </RecentWrapper>
  );
};

export default RecentNews;