import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { useCarousel } from "../../hooks/useCarousel";
import { GatsbyImage } from "gatsby-plugin-image";
import classnames from "classnames";
import slugify from "slugify";

import { useWindowSize } from "../../hooks/useWindowSize";

import {
  ResourcesWrap,
  SliderWrap,
  carouselContent,
  CarouselChild,
  carouselIndicators,
  carouselIndicatorWrap,
  CarouselIndicator,
  slideContentWrap,
  resourceLeftLine,
  resourcePreview,
  resourceTitle,
  resourceDescription,
  Swipe,
} from "./Components";
// 650 850
// 200 250
const Resources = () => {
  const data = useStaticQuery(graphql`
    query ResourcesQueryQA {
      strapiPagerows(id: { eq: "Pagerows_8" }) {
        dynamic_components
      }
      allFile(filter: {parent: {id: {eq: "Pagerows_8"}}}) {
        nodes {      
          id    
          name
          localURL
          childImageSharp {
            gatsbyImageData(width: 500, quality: 90)
          }
        }
      }
    }
  `);
  const resources = data.strapiPagerows.dynamic_components.map(el => {
    if (!el.file_id) el.file_id = {};
    el.file_id.localFile = data.allFile.nodes.find(node => node.id === el.file_id?.localFile___NODE);
    el.file_preview.localFile = data.allFile.nodes.find(node => node.id === el.file_preview?.localFile___NODE);
    // console.log(el);
    return el;
  });
  // console.log(resources);

  const screenRes = useWindowSize().width;
  const slideOptions = {
    slidesToShow: 2,
    interval: 3000,
    length: resources.length,
    margin: 128 + (screenRes - 1366) * 0.13,
    infinite: false,
    responsive: [
      {
        breakpoint: 1200,
        options: {
          slidesToShow: 1,
          margin: 70,
        },
      },
      {
        breakpoint: 768,
        options: {
          keepSlideWidth: true,
          initialSlideWidth: 200 + (screenRes - 650) * 0.25,
          margin: 35,
        },
      },
      {
        breakpoint: 650,
        options: {
          keepSlideWidth: false,
          slidesToShow: 1,
          margin: 10,
          sens: 6,
        },
      },
    ],
  };

  const {
    active,
    setActive,
    handlers,
    style,
    slideStyle,
    dotsLength,
  } = useCarousel(slideOptions, false);

  const generateLink = ({ title, yt_video, file_id: { mime } }) => {
    if (yt_video) {
      return '/video/' + slugify(title)
    } else if (mime === 'application/pdf') {
      return '/pdf/' + slugify(title)
    }
  }

  const generateState = ({ yt_video, file_id: { mime }, pdf_book_effect }) => {
    if (yt_video) {
      return { modal: true, closeOnOverlayClick: true, overlayClassName: "ReactModal__Overlay-yt-embed" }
    } else if (mime === 'application/pdf') {
      if (pdf_book_effect) {
        return { modal: screenRes > 1365 ? true : false, closeOnOverlayClick: false, overlayClassName: "ReactModal__Overlay-pdf-embed" }
      } else {
        return { modal: false }
      }
    }
  }

  return (
    <ResourcesWrap className="hide-on-expanded">
      {resources.length ? (
        <SliderWrap>
          <div
            className="carousel-content"
            css={carouselContent}
            {...handlers}
            style={style}
          >
            {resources.map((el, i) => (
              <CarouselChild
                key={i}
                style={slideStyle}
                className="carousel-child"
              >
                <div css={slideContentWrap}>
                  <Link
                    to={generateLink(el)}
                    state={generateState(el)}
                    draggable={false}
                    css={{ userDrag: "none" }}>
                    <h4 css={resourceTitle} className="resource-name">
                      {el.title}
                    </h4>
                    <p css={resourceDescription}>{el.short_description}</p>
                  </Link>
                </div>
                <div css={{ flex: 1, position: "relative" }}>
                    <svg
                      css={resourceLeftLine}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 23.32 384.2"
                      preserveAspectRatio="xMidYMax slice"
                    >
                      <path
                        fill="none"
                        stroke="#bebebe"
                        d="M1.15 353.58V312.64c0-23.11 17.1-17.57 19.44-35.33V0"
                      />
                      <text transform="rotate(-90 187.1 166.48)">DOWNLOAD</text>
                      <path fill="#818181" d="M0 363.79h20.41v20.41H0z" />
                      <g className="dl-icon">
                        <path fill="#818181" d="M0 363.79h20.41v20.41H0z" />
                        <path fill="#ba0027" d="M.4 363.79h20.41v20.41H.4z" />
                        <path
                          fill="#fff"
                          fillRule="evenodd"
                          d="M10.19 376.58l-2.5-2.5a.59.59 0 11.83-.83l1.48 1.49v-6.82a.59.59 0 011.17 0v6.82l1.49-1.49a.6.6 0 01.84 0 .59.59 0 010 .83l-2.5 2.5a.59.59 0 01-.81 0z"
                        />
                        <path
                          fill="#fff"
                          fillRule="evenodd"
                          d="M16.49 374.17a.58.58 0 00-.59.58v4.12a.58.58 0 01-.59.59H5.9a.58.58 0 01-.59-.59v-4.12a.59.59 0 00-.59-.58.58.58 0 00-.59.58v4.12a1.77 1.77 0 001.77 1.77h9.41a1.77 1.77 0 001.77-1.77v-4.12a.59.59 0 00-.59-.58z"
                        />
                      </g>
                    </svg>
                </div>
              </CarouselChild>
            ))}
          </div>
          <Swipe className="swipe-icon" />
          <ol css={carouselIndicators}>
            {Array.from(Array(dotsLength)).map((_, index) => (
              <li
                key={index}
                css={carouselIndicatorWrap}
                className={classnames("carousel-indicator", {
                  active: active === index,
                })}
                onClick={() => setActive(index)}
                role="presentation"
              >
                <CarouselIndicator />
              </li>
            ))}
          </ol>
        </SliderWrap>
      ) : (
        ""
      )}
    </ResourcesWrap>
  );
};

export default Resources;