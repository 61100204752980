import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const MemberLogosWrap = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 41%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1200px) {
    /* width: 50%; */
    /* right: 1%; */
  }
  @media (max-width: 768px) {
    position: relative;
    top: initial;
    right: initial;
    padding: 25px;
    left: 0;
    width: initial;
    .member-logo-cont {
      flex-wrap: wrap;
      justify-content: center;
    }
    .member-logo-element {
      flex: 150px;
      max-width: initial;
      margin-bottom: 15px;
    }
    .member-logo {
      max-height: 50px;
      margin: 0 auto;
    }
  }
`;

export const MemberLogosContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  padding: 15px;
  @media (min-width: 769px) {
    margin-bottom: 30px;
  }
`;

export const MemberLogosColumn = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 0 auto;
`;

export const memberLogo = css`
  max-width: 310px;
  height: 100%;
  max-height: calc(100px + (100vw - 1366px) * 0.09);
  img {
    object-fit: contain !important;
  }
`;

export const memberLogoLink = css`
  @media (min-width: 760px) {
    display: flex;
    flex-flow: column;
    align-items: center;
    p {
      margin-bottom: 0;
      text-align: center;
      font-size: 16px;
    }
  }
  @media (min-width: 769px) and (max-width: 1365px) {
    p {
      font-size :14px;
    }
  }
  @media (max-width: 768px) {
    max-width: calc(205px + (100vw - 1366px) * 0.045);
    margin-top: calc(10px + (100vw - 1366px) * 0.0181);
    margin-bottom: calc(10px + (100vw - 1366px) * 0.0181);
    text-align: center;
  }
`;
