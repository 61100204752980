import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Scrollbar from "../../containers/Scrollbar";
import Media from "react-media";

import {
  FactsContainer,
  FactBox,
  FactsWrapper,
  FactCount,
  FactName,
} from "./Components";

const CompanyFacts = () => {
  const data = useStaticQuery(graphql`
    query FactsQueryAq {
      strapiPagerows(internal_id: { eq: "company_facts" }) {
        name
        dynamic_components
      }
    }
  `);
  const { dynamic_components: facts } = data.strapiPagerows;

  return (
    <FactsContainer>
      <div style={{ display: "flex", height: "100%" }}>
        <Media
          queries={{
            mobile: { maxWidth: 1365 },
          }}
          defaultMatches={{ mobile: true }}
        >
          {(matches) =>
            matches.mobile ? (
              <FactsWrapper className="hide-on-expanded">
                {facts.map((el, i) => (
                  <FactBox key={i}>
                    <FactCount>{el.count}</FactCount>
                    <FactName>{el.name}</FactName>
                  </FactBox>
                ))}
              </FactsWrapper>
            ) : (
              <Scrollbar wrapper={false}>
                <FactsWrapper className="hide-on-expanded">
                  {facts.map((el, i) => (
                    <FactBox key={i}>
                      <FactCount>{el.count}</FactCount>
                      <FactName>{el.name}</FactName>
                    </FactBox>
                  ))}
                </FactsWrapper>
              </Scrollbar>
            )
          }
        </Media>
      </div>
    </FactsContainer>
  );
};

export default CompanyFacts;