import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import LeftBar from "../../containers/LeftBar";
import {
  CarouselChild,
  leftBarWrap,
  slideContent,
  slideImageWrap,
  newsThumb,
  recentNewsTitle,
  Excerpt,
} from "./Components";

const PlaceholderImage = ({ alt }) => (
  <div style={{ width: 381, height: 381, backgroundColor: "#eee" }} aria-label={alt}></div>
);

const CarouselChildComponent = React.memo(({ slideStyle, slug, thumbImg, title, alt, excerpt }) => (
  <CarouselChild css={slideStyle}>
    <div css={leftBarWrap}>
      <LeftBar
        template="news"
        contentTitle="News"
        linkTo={`/article/${slug}`}
        barColor="#BEBEBE"
        titleColor="#AEAEAE"
      />
    </div>
    <div css={slideContent}>
      <Link
        to={`/article/${slug}`}
        state={{ modal: true }}
        css={{ userDrag: "none" }}
        draggable={false}
      >
        <div css={slideImageWrap}>
          {thumbImg ? (
            <GatsbyImage image={thumbImg} draggable={false} css={newsThumb} alt={alt || ''} />
          ) : (
            <PlaceholderImage alt={alt || 'No image available'} />
          )}
        </div>
        <h3 css={recentNewsTitle}>{title}</h3>
        <Excerpt>{excerpt}</Excerpt>
      </Link>
    </div>
  </CarouselChild>
));

export default CarouselChildComponent;
