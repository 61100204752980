import React, { useState, useRef, createRef, useCallback } from "react";
import { graphql, useStaticQuery } from "gatsby";
import classnames from "classnames";
import { gsap } from "gsap";
import Shape from "../../containers/RowShapes";
import Media, { useMedia } from "react-media";

import CloseLine from "../../assets/svg/close-line.inline.svg";
// import ScrollBar from "react-scrollbars-custom";
import ScrollBar from "../../containers/Scrollbar";

import GlobalMap from "./map";
import {
  MapBackground,
  MapWrapper,
  ListContainer,
  Continent,
  ContinentName,
  CountryList,
  CountryName,
  GoBack,
} from "./Components";

gsap.config({ nullTargetWarn: false })

const continentNames = {
  europe: "Europe",
  asia: "Asia",
  southamerica: "South America",
  nothamerica: "North America",
  africa: "Africa",
  australia: "Australia",
};

const GlobalFootprint = () => {
  const data = useStaticQuery(graphql`
    query CountryQuerySa {
      allStrapiCountry(filter: { isActive: { eq: true } }) {
        nodes {
          continent
          name
        }
      }
    }
  `);
  const [activeCont, setActiveCont] = useState("");

  const refs = useRef({
    europe: {
      fl: createRef(null),
      sh: createRef(null),
    },
    asia: {
      fl: createRef(null),
      sh: createRef(null),
    },
    southamerica: {
      fl: createRef(null),
      sh: createRef(null),
    },
    africa: {
      fl: createRef(null),
      sh: createRef(null),
    },
  });
  const currentFlagSh = useRef(null);
  const currentFlagFl = useRef(null);
  const mobileView = useMedia({
    query: "(max-width: 768px)",
  });

  const continents = data.allStrapiCountry.nodes.reduce((prev, curr) => {
    (prev[curr["continent"]] = prev[curr["continent"]] || []).push(curr.name);
    return prev;
  }, {});
  const contIndexes = Object.keys(continents);

  const contsRef = useRef(
    [...Array(Object.keys(continents).length)].map(() => createRef())
  );

  const hideAll = gsap
    .timeline({ paused: true })
    .to(
      document.querySelectorAll(".go-back"),
      {
        autoAlpha: 0,
        ease: "power2.out",
        duration: 0.3,
      },
      0
    )
    .to(
      document.querySelectorAll(".country-gutter"),
      {
        height: 0,
        duration: 0.3,
      },
      0.3
    )
    .to(
      document.querySelectorAll(".country-list"),
      {
        yPercent: 0,
        duration: 0.3,
      },
      0.3
    );

  const toggleMobList = (cont) => {
    const index = contIndexes.indexOf(cont);
    const animateMobCont = contsRef.current[index].current.children[1];
    if (cont !== activeCont) {
      gsap.set(animateMobCont, { height: "auto" });
      gsap.from(animateMobCont, {
        height: 0,
      });
    } else {
      gsap.to(animateMobCont, { height: 0 });
    }
  };

  const toggleList = (cont) => {
    const index = contIndexes.indexOf(cont);
    const currCountryList = contsRef.current[index].current.querySelector(
      ".country-list"
    );

    gsap
      .timeline()
      .to(
        contsRef.current[index].current.querySelector(".country-gutter"),
        {
          height: currCountryList.offsetHeight,
        },
        0
      )
      .to(
        currCountryList,
        {
          yPercent: 100,
          autoAlpha: 1,
        },
        0
      )
      .to(
        contsRef.current[index].current.querySelector(".go-back"),
        { autoAlpha: 1, ease: "power2.out" },
        0.5
      );
  };

  const showFlag = useCallback((cont) => {
    currentFlagSh.current = gsap.to(refs.current[cont].sh.current, {
      rotate: 0,
      opacity: 1,
      duration: 0.4,
    });

    currentFlagFl.current = gsap.to(refs.current[cont].fl.current, {
      y: 0,
      opacity: 1,
      duration: 0.4,
    });
  }, []);

  const hideFlag = useCallback((cont) => {
    if (cont) {
      gsap.to(refs.current[cont].sh.current, {
        rotate: -10,
        opacity: 0,
        duration: 0.4,
      });

      gsap.to(refs.current[cont].fl.current, {
        y: -30,
        opacity: 0,
        duration: 0.4,
      });
    } else {
      if (currentFlagSh.current) {
        currentFlagSh.current.pause();
        currentFlagSh.current.reverse();
      }
      if (currentFlagFl.current) {
        currentFlagFl.current.pause();
        currentFlagFl.current.reverse();
      }
    }
  }, []);

  const onClickContinent = (cont) => {
    if (cont === "close") {
      hideFlag(activeCont);
      setActiveCont("");
      hideAll.restart();
    } else if (cont !== activeCont) {
      if (activeCont) {
        hideFlag(activeCont);
        if (mobileView) {
          toggleMobList(activeCont);
          toggleMobList(cont);
        } else {
          hideAll.eventCallback("onComplete", () => toggleList(cont)).restart();
        }
      } else {
        if (mobileView) {
          toggleMobList(cont);
        } else {
          toggleList(cont);
        }
      }
      showFlag(cont);
      setActiveCont(cont);
    } else if (cont === activeCont) {
      hideFlag(activeCont);
      if (mobileView) {
        toggleMobList(cont);
      } else {
        hideAll.restart();
      }
      setActiveCont("");
    }
  };

  const onMouseEnter = (e) => {
    if (mobileView) return;
    const id = e.target.closest("g").id;

    showFlag(id);
  };

  const onMouseLeave = (e) => {
    if (mobileView) return;
    const id = e.target.closest("g").id;
    if (activeCont !== id) {
      hideFlag(id);
    }
  };

  const onMapClick = (e) => {
    const id = e.target.closest("g").id;
    // console.log(id, activeCont);

    if (activeCont !== id) {
      if (activeCont) {
        hideFlag(activeCont);
        if (mobileView) {
          toggleMobList(activeCont);
          toggleMobList(id);
          showFlag(id);
        } else {
          hideAll.eventCallback("onComplete", () => toggleList(id)).restart();
        }
      } else {
        if (mobileView) {
          toggleMobList(id);
          showFlag(id);
        } else {
          toggleList(id);
        }
      }
      setActiveCont(id);
    } else if (activeCont === id) {
      hideFlag(activeCont);
      if (mobileView) {
        toggleMobList(id);
      } else {
        hideAll.restart();
      }
      setActiveCont("");
    }
  };

  const gridAreas = ["a", "b", "c", "d"];

  return (
    <>
      <MapWrapper>
        <MapBackground className="map-background">
          <GlobalMap
            styles={{ position: "absolute", top: 0, left: 0 }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onMapClick}
            activeCont={activeCont}
            ref={refs}
          />
        </MapBackground>
      </MapWrapper>
      <Media
        query="(min-width: 769px)"
        render={() => <Shape shape="global_footprint" />}
      />
      {continents && (
        <ListContainer className="hide-on-expanded">
          <Media
            queries={{
              xs: "(max-width: 480px)",
              mobile: "(max-width: 768px)",
            }}
          >
            {(matches) => (
              <>
                {matches.xs ? (
                  <ul className="all-continents-mob">
                    {Object.keys(continents).map((el, i) => (
                      <Continent
                        key={i}
                        ref={contsRef.current[i]}
                        className={classnames("continent-li", {
                          active: activeCont === el,
                        })}
                        css={{ gridArea: gridAreas[i] }}
                      >
                        <ContinentName onClick={() => onClickContinent(el, i)}>
                          {continentNames[el]}
                        </ContinentName>
                        {
                          <CountryList className="country-list">
                            {continents[el].map((country, index) => (
                              <CountryName key={index}>{country}</CountryName>
                            ))}
                          </CountryList>
                        }
                      </Continent>
                    ))}
                  </ul>
                ) : matches.mobile ? (
                  <ul className="all-continents-mob">
                    <div>
                      {Object.keys(continents)
                        .slice(0, 2)
                        .map((el, i) => (
                          <Continent
                            key={i}
                            ref={contsRef.current[i]}
                            className={classnames("continent-li", {
                              active: activeCont === el,
                            })}
                            css={{ gridArea: gridAreas[i] }}
                          >
                            <ContinentName
                              onClick={() => onClickContinent(el, i)}
                            >
                              {continentNames[el]}
                            </ContinentName>
                            <CountryList className="country-list">
                              {continents[el].map((country, index) => (
                                <CountryName key={index}>{country}</CountryName>
                              ))}
                            </CountryList>
                          </Continent>
                        ))}
                    </div>
                    <div>
                      {Object.keys(continents)
                        .slice(2, 4)
                        .map((el, i) => (
                          <Continent
                            key={i + 2}
                            ref={contsRef.current[i + 2]}
                            className={classnames("continent-li", {
                              active: activeCont === el,
                            })}
                            css={{ gridArea: gridAreas[i] }}
                          >
                            <ContinentName
                              onClick={() => onClickContinent(el, i + 2)}
                            >
                              {continentNames[el]}
                            </ContinentName>
                            <CountryList className="country-list">
                              {continents[el].map((country, index) => (
                                <CountryName key={index}>{country}</CountryName>
                              ))}
                            </CountryList>
                          </Continent>
                        ))}
                    </div>
                  </ul>
                ) : matches.tablet ? (
                  <ScrollBar wrapper={false}>
                    <ul className="all-continents-mob">
                      {Object.keys(continents).map((el, i) => (
                        <Continent
                          key={i}
                          ref={contsRef.current[i]}
                          className={classnames("continent-li", {
                            active: activeCont === el,
                          })}
                          css={{ gridArea: gridAreas[i] }}
                        >
                          <ContinentName
                            onClick={() => onClickContinent(el, i)}
                          >
                            {continentNames[el]}
                          </ContinentName>
                          {
                            <CountryList className="country-list">
                              {continents[el].map((country, index) => (
                                <CountryName key={index}>{country}</CountryName>
                              ))}
                            </CountryList>
                          }
                        </Continent>
                      ))}
                    </ul>
                  </ScrollBar>
                ) : (
                  <ul
                    className={classnames({
                      "all-continents": activeCont === "",
                    })}
                  >
                    {Object.keys(continents).map((el, i) => (
                      <Continent
                        key={i}
                        ref={contsRef.current[i]}
                        className={classnames("continent-li", {
                          active: activeCont === el,
                        })}
                      >
                        <ContinentName onClick={() => onClickContinent(el, i)}>
                          {continentNames[el]}
                        </ContinentName>
                        {
                          <div
                            className="country-gutter"
                            css={{ overflow: "hidden", position: "relative" }}
                          >
                            <CountryList className="country-list">
                              <ScrollBar>
                                {continents[el].map((country, index) => (
                                  <CountryName key={index}>
                                    {country}
                                  </CountryName>
                                ))}
                              </ScrollBar>
                              <GoBack className="go-back">
                                <CloseLine
                                  onClick={() => onClickContinent("close", i)}
                                />
                              </GoBack>
                            </CountryList>
                          </div>
                        }
                      </Continent>
                    ))}
                  </ul>
                )}
              </>
            )}
          </Media>
        </ListContainer>
      )}
    </>
  );
};

export default GlobalFootprint;