import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Media from "react-media";

import { GatsbyImage } from "gatsby-plugin-image";

import {
  MemberLogosWrap,
  MemberLogosContainer,
  memberLogo,
  memberLogoLink,
  MemberLogosColumn,
} from "./Components";

const MemberLogoElement = React.memo((props) => (
  <a
    className="member-logo-element"
    css={memberLogoLink}
    href={props.urlAddress}
    target="_blank"
    rel="noopener noreferrer"
  >
    <GatsbyImage image={props.logoImage} className="member-logo" css={memberLogo} alt={props.alt} />
    <p>{props.title}</p>
  </a>
));

const Membership = () => {
  const data = useStaticQuery(graphql`
    query MembershipQueryAQ {
      strapiPagerows(id: { eq: "Pagerows_9" }) {
        dynamic_components
      }
      allFile(filter: {parent: {id: {eq: "Pagerows_9"}}}) {
        nodes {      
          id    
          name
          localURL
          childImageSharp {
            gatsbyImageData(width: 500, quality: 90)
          }
        }
      }
    }
  `);
  // const { dynamic_components: membership } = data.strapiPagerows;
  const membership = data.strapiPagerows.dynamic_components.map(el => {
    el.logo_image.localFile = data.allFile.nodes.find(node => node.id === el.logo_image.localFile___NODE);
    return el;
  });

  return (
    <MemberLogosWrap className="hide-on-expanded member-logos">
      <MemberLogosContainer className="member-logo-cont">
        <Media query="(min-width: 769px)">
          {(matches) =>
            matches ? (
              membership.map((el, i) => (
                <MemberLogoElement
                  key={i}
                  urlAddress={el.url_address}
                  logoImage={el.logo_image.localFile.childImageSharp.gatsbyImageData}
                  title={el.membership_logo_title}
                  alt={el.logo_image.alternativeText || ''}
                />
              ))
              // <>
              //   <MemberLogosColumn>
              //     {membership.slice(0, 2).map((el, i) => (
              //       <MemberLogoElement
              //         key={i}
              //         urlAddress={el.url_address}
              //         logoImage={el.logo_image.localFile.childImageSharp.gatsbyImageData}
              //         title={el.membership_logo_title}
              //         alt={el.logo_image.alternativeText || ''}
              //       />
              //     ))}
              //   </MemberLogosColumn>
              //   <MemberLogosColumn>
              //     {membership.slice(2, 5).map((el, i) => (
              //       <MemberLogoElement
              //         key={i}
              //         urlAddress={el.url_address}
              //         logoImage={el.logo_image.localFile.childImageSharp.gatsbyImageData}
              //         title={el.membership_logo_title}
              //         alt={el.logo_image.alternativeText || ''}
              //       />
              //     ))}
              //   </MemberLogosColumn>
              // </>
            ) : (
              membership.map((el, i) => (
                <MemberLogoElement
                  key={i}
                  urlAddress={el.url_address}
                  logoImage={el.logo_image.localFile.childImageSharp.gatsbyImageData}
                  title={el.membership_logo_title}
                  alt={el.logo_image.alternativeText || ''}
                />
              ))
            )
          }
        </Media>
      </MemberLogosContainer>
    </MemberLogosWrap>
  );
};

export default Membership;