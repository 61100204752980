import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const MediaLogosWrap = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 56%;
  display: flex;
  @media (max-width: 1365px) {
    width: 49%;
    .media-logo-link {
      margin-bottom: 10px;
    }
    .media-logo {
      max-width: 400px;
    }
  }
  @media (max-width: 768px) {
    width: initial;
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 25px;
    .media-logo-cont {
      height: initial;
      justify-content: center;
    }
    .media-logo-link {
      margin: 0 20px 25px;
    }
    .last-row & {
      padding-bottom: 70px;
    }
  }
  @media (max-width: 480px) {
    .media-logo-cont {
      justify-content: space-around;
    }
    .media-logo-link {
      margin: 0 0 25px;
    }
    .media-logo {
      max-width: 300px;
    }
  }
  @media (max-width: 360px) {
    .media-logo-cont {
      flex-flow: column;
    }
    .media-logo {
      max-width: 270px;
    }
  }
`;

export const MediaLogosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 63vh;
  align-self: center;
  padding: 0 calc(10px + (100vw - 1366px) * 0.162);
  @media (min-width: 1921px) {
    justify-content: center;
  }
`;

export const mediaLogo = (props) => css`
  max-height: calc(40px + (100vw - 1366px) * 0.055);
  img {
    object-fit: contain !important;
  }
  @media (max-width: 1366px) {
    max-height: 40px;
  }
  @media (max-width: 768px) {
    max-height: 60px;
    height: 60px !important;
    width: ${Math.round(props.aspectRatio * 60)}px !important;
  }
`;

export const mediaLogoLink = css`
  margin: 0 auto;
  text-align: center;
  @media (min-width: 1921px) {
    margin: 0 calc(0px + (100vw - 1366px) * 0.054);
  }
`;
