import styled from "@emotion/styled";
import factboxbg from "../../assets/images/factbox.png";

export const FactsContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 100px;
  @media (max-width: 1365px) {
    padding-left: 20%;
    padding-right: 15px;
  }
  @media (max-width: 1024px) {
    padding: 40px 15px;
  }
  @media (max-width: 600px) {
    padding: 80px 15px 25px 15px;
  }
`;

export const FactBox = styled.div`
  background-image: url(${factboxbg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top left;
  width: 100%;
  height: 17vh;
  margin-bottom: 3%;
  display: flex;
  @media (min-width: 1921px) {
    height: 160px;
  }
  @media (max-width: 1365px) {
    height: 11vw;
  }
  @media (max-width: 768px) {
    height: 78px;
    margin-bottom: 0;
  }
`;

export const FactsWrapper = styled.div`
  width: 66vw;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  align-content: center;
  @media (max-width: 1365px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    width: 100%;
    display: flex;
    flex-flow: column;
    height: initial;
    margin: 0;
  }
`;

export const FactCount = styled.div`
  width: 30%;
  line-height: 1;
  font-size: calc(51px + (100vw - 1366px) * 0.03069);
  color: #808080;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64%;
  @media (min-width: 1921px) {
    width: 190px;
    font-size: 68px;
  }
  @media (max-width: 1365px) {
    width: 15vw;
    font-size: 42px;
  }
  @media (max-width: 600px) {
    font-size: 32px;
    width: 105px;
  }
`;

export const FactName = styled.div`
  line-height: 1.1;
  font-size: calc(24px + (100vw - 1366px) * 0.0108);
  color: #1d1d1b;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 64%;
  padding: 0 4% 0 13%;
  flex: 1;
  @media (min-width: 1921px) {
    font-size: 30px;
  }
`;

export const LeftBarWrapper = styled.div`
  width: 76vh;
  position: absolute;
  top: 0;
  left: -14%;
  .left-bar-svg {
    width: 100%;
  }
`;
