import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const EventsWrap = styled.div`
  position: absolute;
  right: 6%;
  top: 15%;
  width: 25vw;
  z-index: 1;
  display: flex;
  flex-flow: column;
  @media (max-width: 1200px) {
    right: 3%;
    width: 37vw;
    height: 100%;
    top: 0;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .ScrollbarsCustom-Content {
    flex-flow: column;
    align-items: flex-start;
  }
  @media (max-width: 768px) {
    top: initial;
    left: 0;
    bottom: 0;
    width: auto;
    height: initial;
    right: 0;
    padding: 25px 25px 0;
    position: relative;
    margin-top: -40px;
  }
`;

export const eventsTitle = css`
  text-transform: uppercase;
  color: #fff;
  font-size: 30px;
  margin: 0 0 35px;
  flex: 1;
  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

export const SingleEvent = styled.div`
  font-size: 14px;
  color: #d7d7d7;
  line-height: 1.35;
  margin-bottom: 25px;
  p {
    margin: 0 0 4px;
  }
`;

export const eventTitle = css`
  color: #b1b1b1;
  font-size: 16px;
  font-family: var(--main-font);
  font-weight: 700;
  margin: 0 0 4px;
  transition: color 0.3s;
  a:hover > &,
  a:focus > & {
    color: #ba0027;
  }
`;
