import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const TimelineWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0 !important;
  @media (max-width: 768px) {
    bottom: 49%;
    img {
      object-position: left !important;
    }
    ~ svg {
      opacity: 1;
    }
    position: relative;
    height: 100vw;
    max-height: 450px;
  }
`;

export const YearsWrap = styled.div`
  position: absolute;
  right: calc(98px + (100vw - 1366px) * 0.235);
  top: 50%;
  transform: translateY(-70%);
  z-index: 1;
  display: flex;
  flex-flow: column;
  @media (max-width: 1024px) {
    right: 18px;
  }
  @media (max-width: 768px) {
    left: 0;
    right: 0;
    top: 0;
    align-items: center;
    transform: none;
    position: relative;
    padding-top: 35px;
  }
`;

export const yearsContainer = css`
  display: flex;
  align-items: center;
`;

export const CarouselChild = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  user-select: none;
  opacity: 0;
  z-index: 98;
  transition: none;
  img {
    user-drag: none;
  }
  &.animating {
    transition: opacity 0.5s;
    z-index: 99;
    opacity: 1;
  }
  &.fadeIn {
    opacity: 1;
  }
`;

export const carouselContent = css`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  position: relative;
  height: 100%;
`;

export const ActiveYear = styled.div`
  display: flex;
  position: relative;
  width: 350px;
  @media (max-width: 1365px) {
    width: 300px;
  }
  @media (max-width: 420px) {
    width: 260px;
  }
`;

export const SubYear = styled.div`
  font-size: 130px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  background-color: #404040;
  padding: 5px 15px 15px;
  margin: 0 3px;
  font-family: var(--sec-font);
  flex: 1;
  text-align: center;
  @media (max-width: 1365px) {
    font-size: 100px;
  }
  @media (max-width: 420px) {
    font-size: 86px;
  }
`;

export const ActiveDesc = styled.div`
  color: #fff;
  text-align: left;
  margin: 20px 0;
  line-height: 1.35;
  font-size: 16px;
  z-index: 1;
  padding: 0 2px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  @media (max-width: 1365px) {
    width: 350px;
  }
  @media (max-width: 768px) {
    width: 400px;
    margin: 20px auto;
    text-align: center;
    position: relative;
    min-height: 42px;
    margin-bottom: 35px;
  }
  @media (max-width: 420px) {
    width: 100%;
    padding: 0 15px;
    text-align: center;
  }
`;

export const currYear = css`
  display: flex;
  position: relative;
  z-index: 1;
  width: 100%;
  perspective: 400px;
  &.animate .top-part {
    transition: transform 0.3s ease-in;
    transform: rotateX(-180deg);
  }
`;

export const nextYear = css`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  perspective: 400px;
  z-index: 0;
  transition: z-index 0s linear;
  .bot-part {
    transform: rotateX(180deg);
  }
  &.animate {
    transition: z-index 0.3s linear;
    z-index: 1;
    .bot-part {
      transition: transform 0.3s ease-in;
      transform: rotateX(0deg);
    }
  }
`;

export const topPart = css`
  display: flex;
  width: 100%;
  height: calc(50% - 2px);
  overflow: hidden;
  transform-origin: 50% calc(100% + 2px);
  transition: none;
  .subyear {
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
  }
`;

export const botPart = css`
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: calc(50% - 2px);
  overflow: hidden;
  transform-origin: 50% -2px;
  .subyear {
    border-bottom-right-radius: 23px;
    border-bottom-left-radius: 23px;
  }
`;

export const YearControls = styled.div`
  z-index: 1;
`;

export const yearButton = css`
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
  padding: 5px 15px;
  display: flex;
  > svg {
    width: 2.68vw;
    color: #bebebe;
    fill: currentColor;
  }
  @media (max-width: 1365px) {
    padding: 5px 7px;
    > svg {
      width: 26px;
    }
  }
`;

export const yeardash = css`
  width: calc(100% - 40px);
  height: 1px;
  margin: 8px auto;
  background-color: #bebebe;
  @media (max-width: 1365px) {
    width: 22px;
  }
`;
