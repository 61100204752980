import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { GatsbyImage } from "gatsby-plugin-image";

import {
  MediaLogosWrap,
  MediaLogosContainer,
  mediaLogoLink,
  mediaLogo,
} from "./Components";

const MediaLogoElement = React.memo((props) => (
  <a
    className="media-logo-link"
    css={mediaLogoLink}
    href={props.urlAddress}
    target="_blank"
    rel="noopener noreferrer"
  >
    <GatsbyImage
      image={props.logoImage}
      className="media-logo"
      alt={props.alt}
      css={mediaLogo({
        aspectRatio: props.logoImage.width / props.logoImage.height,
      })} />
  </a>
));

const MediaPartners = () => {
  const data = useStaticQuery(graphql`
    query MediaPartnersQueryQ {
      strapiPagerows(id: { eq: "Pagerows_10" }) {
        dynamic_components
      }
      allFile(filter: {parent: {id: {eq: "Pagerows_10"}}}) {
        nodes {
          id
          name
          localURL
          childImageSharp {
            gatsbyImageData(layout: FIXED, height: 62, quality: 90)
          }
        }
      }
    }
  `);
  // const { dynamic_components: mediaPartners } = data.strapiPagerows;
  const mediaPartners = data.strapiPagerows.dynamic_components.map(el => {
    el.logo_image.localFile = data.allFile.nodes.find(node => node.id === el.logo_image.localFile___NODE);
    return el;
  });
  // console.log(mediaPartners);

  return (
    <MediaLogosWrap className="hide-on-expanded">
      <MediaLogosContainer className="media-logo-cont">
        {mediaPartners.map((el, i) => (
          <MediaLogoElement
            key={i}
            urlAddress={el.url_address}
            logoImage={el.logo_image.localFile.childImageSharp.gatsbyImageData}
            alt={el.logo_image.alternativeText || ''}
          />
        ))}
      </MediaLogosContainer>
    </MediaLogosWrap>
  );
};

export default MediaPartners;